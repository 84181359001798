import * as z from 'zod'
import { SportShape } from './sport'
import { GameShape } from './game'

export const MigrateProfileToGameRegistrationLifecycleEventShape = z.object({
    type: z.literal('migrate-profile-to-game-registration'),
    sport: SportShape,
    season: z.string(),
    game: GameShape,
    chunkSize: z.number().optional(),
})
export type MigrateProfileToGameRegistrationLifecycleEvent = z.TypeOf<
    typeof MigrateProfileToGameRegistrationLifecycleEventShape
>

export const MigrateAllowNotificationsForExistingCompetitionEventShape = z.object(
    {
        type: z.literal('migrate-allow-notifications-for-existing-comp'),
        sport: SportShape,
        season: z.string(),
        game: GameShape,
        chunkSize: z.number().optional(),
    },
)
export type MigrateAllowNotificationsForExistingCompetitionLifecycleEvent = z.TypeOf<
    typeof MigrateAllowNotificationsForExistingCompetitionEventShape
>

export const MigrateOriginIdForExistingCompetitionLifecycleShape = z.object({
    type: z.literal('migrate-origin-id-for-existing-comp'),
    chunkSize: z.number().optional(),
})
export type MigrateOriginIdForExistingCompetitionLifecycleEvent = z.TypeOf<
    typeof MigrateOriginIdForExistingCompetitionLifecycleShape
>

export const MigrateRemoveArchivedCompetitionsEventShape = z.object({
    type: z.literal('migrate-remove-archived-competitions'),
    currentSeason: z.string(),
    chunkSize: z.number().optional(),
})

export type MigrateRemoveArchivedCompetitionsLifecycleEvent = z.TypeOf<
    typeof MigrateRemoveArchivedCompetitionsEventShape
>

export const MigrateAddStateToGameRegistrationsEventShape = z.object({
    type: z.literal('migrate-add-state-to-game-registrations'),
    sport: SportShape,
    season: z.string(),
    game: GameShape,
    chunkSize: z.number().optional(),
})

export type MigrateAddStateToGameRegistrationsLifecycleEvent = z.TypeOf<
    typeof MigrateAddStateToGameRegistrationsEventShape
>

export const MigrateCompetitionNameEventLifecycleShape = z.object({
    type: z.literal('migrate-competition-names'),
    chunkSize: z.number().optional(),
})
export type MigrateCompetitionNameLifecycleEvent = z.TypeOf<
    typeof MigrateCompetitionNameEventLifecycleShape
>

export const MigrateSeedExpertsLifecycleShape = z.object({
    type: z.literal('migration-seed-experts-lifecycle-event'),
    experts: z.array(
        z.object({
            userSub: z.string(),
            title: z.string(),
            avatar: z.string(),
            fullName: z.string(),
        }),
    ),
})
export type MigrateSeedExpertsLifecycleEvent = z.TypeOf<
    typeof MigrateSeedExpertsLifecycleShape
>
