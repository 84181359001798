import * as z from 'zod'
import {
    NukeDataLifecycleEventShape,
    ResetEnvDataLifecycleEventShape,
} from './environment'
import {
    MigrateProfileToGameRegistrationLifecycleEventShape,
    MigrateProfileToGameRegistrationLifecycleEvent,
    MigrateAllowNotificationsForExistingCompetitionEventShape,
    MigrateAllowNotificationsForExistingCompetitionLifecycleEvent,
    MigrateRemoveArchivedCompetitionsEventShape,
    MigrateCompetitionNameEventLifecycleShape,
    MigrateOriginIdForExistingCompetitionLifecycleShape,
    MigrateSeedExpertsLifecycleShape,
    MigrateAddStateToGameRegistrationsEventShape,
} from './migrations'
import {
    MatchDetailsUpdatedLifecycleEvent,
    MatchScheduleUpdatedLifecycleEventShape,
    FixtureCreateLifecycleEvent,
    FixtureCreateLifecycleEventShape,
} from './fixture'
import {
    MatchStatusLifecycleEventShape,
    MatchStatusPlayerStatsLifecycleEventShape,
} from './match'
import {
    MatchHomeAwaySwappedLifecycleEventShape,
    MatchSystemLockedLifecycleEventShape,
    MatchSystemUnlockedLifecycleEventShape,
} from './match-admin'
import {
    RoundAddLifecycleEventShape,
    RoundEndLifecycleEvent,
    RoundEndLifecycleEventShape,
} from './round'
import { FormguideUpdatedLifecycleEventShape } from './formguide'
import { FormguidePlayerStatsUpdatedLifecycleEventShape } from './formguide-player'
import {
    SquadListsPlayerUpdatedLifecycleEventShape,
    SquadListsPlayerUpdatedLifecycleEvent,
} from './squad'
import { RecalculateUserLifeCycleEventShape } from './user'
import { LadderUpdatedLifecycleEventShape } from './ladder'
import {
    SeasonUpdatedLifecycleEventShape,
    ReconcileMatchRoundEventShape,
    ToggleSeasonLockEventShape,
    SeasonConfiguredLifecycleEventShape,
} from './season'
import { RoundChangeFeaturedMatchLifecycleEventShape } from './round'
import { CalculateTippingRemindersEventShape } from './push-notifications'
import { DeletePersonalNotificationsEventShape } from './personal-notifications'
import { CalculateIngestAlertEventShape } from './ingest-alert'

export type FixtureLifecycleEvents =
    | MatchDetailsUpdatedLifecycleEvent
    | FixtureCreateLifecycleEvent

export type RoundLifecycleEvents = RoundEndLifecycleEvent

export type SquadListLifecycleEvents = SquadListsPlayerUpdatedLifecycleEvent

export type MigrationEvents =
    | MigrateProfileToGameRegistrationLifecycleEvent
    | MigrateAllowNotificationsForExistingCompetitionLifecycleEvent

export const LifecycleQueryShape = z.union([
    MatchStatusLifecycleEventShape,
    MatchStatusPlayerStatsLifecycleEventShape,
    RoundEndLifecycleEventShape,
    MatchScheduleUpdatedLifecycleEventShape,
    FixtureCreateLifecycleEventShape,
    SquadListsPlayerUpdatedLifecycleEventShape,
    ResetEnvDataLifecycleEventShape,
    MigrateProfileToGameRegistrationLifecycleEventShape,
    FormguideUpdatedLifecycleEventShape,
    FormguidePlayerStatsUpdatedLifecycleEventShape,
    LadderUpdatedLifecycleEventShape,
    NukeDataLifecycleEventShape,
    MatchHomeAwaySwappedLifecycleEventShape,
    MatchSystemLockedLifecycleEventShape,
    MatchSystemUnlockedLifecycleEventShape,
    RoundChangeFeaturedMatchLifecycleEventShape,
    RecalculateUserLifeCycleEventShape,
    SeasonUpdatedLifecycleEventShape,
    SeasonConfiguredLifecycleEventShape,
    ReconcileMatchRoundEventShape,
    ToggleSeasonLockEventShape,
    MigrateAllowNotificationsForExistingCompetitionEventShape,
    MigrateOriginIdForExistingCompetitionLifecycleShape,
    MigrateRemoveArchivedCompetitionsEventShape,
    MigrateCompetitionNameEventLifecycleShape,
    MigrateSeedExpertsLifecycleShape,
    MigrateAddStateToGameRegistrationsEventShape,
    RoundAddLifecycleEventShape,
    CalculateTippingRemindersEventShape,
    CalculateIngestAlertEventShape,
    DeletePersonalNotificationsEventShape,
])

export type LifecycleEvents = z.TypeOf<typeof LifecycleQueryShape>

export * from './fixture'
export * from './game'
export * from './match'
export * from './match-admin'
export * from './round'
export * from './migrations'
export * from './formguide'
export * from './formguide-player'
export * from './ladder'
export * from './season'
export * from './sport'
export * from './squad'
export * from './user'
export * from './push-notifications'
export * from './ingest-alert'
