import * as z from 'zod'

export type CalculateIngestAlertEvent = z.TypeOf<
    typeof CalculateIngestAlertEventShape
>

export const CalculateIngestAlertEventShape = z.object({
    type: z.literal('trigger-ingest-alert-check'),
    bucketName: z.string(),
})
