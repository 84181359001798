import { useReactOidc } from '@axa-fr/react-oidc-context'
import {
    Box,
    Button,
    Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Spinner,
    Text,
    useDisclosure,
    Divider,
    ModalHeader,
    Input,
    FormControl,
    IconButton,
} from '@chakra-ui/react'
import { CopyIcon } from '@chakra-ui/icons'
import React, { useState } from 'react'
import { BlockHeader, Pen } from './Shared'
import { environment } from '../../../environments/environment'
import { useUpdateUserEmailAuth } from '../../data'

export interface AccountProps {
    subject?: string
    email?: string
}
export interface ResetPasswordEmail {
    status: 'initial' | 'success' | 'error'
    isLoading: boolean
}

export function useResetPasswordEmail(email: string) {
    const [resetPasswordEmail, setResetPasswordEmail] = useState({
        status: 'initial',
        isLoading: false,
    } as ResetPasswordEmail)

    async function sendResetPasswordEmailRequest() {
        setResetPasswordEmail({
            status: resetPasswordEmail.status,
            isLoading: true,
        })
        try {
            const resp = await fetch(
                `${environment.userAuthEndpoint.replace(
                    '/connect',
                    '',
                )}/forgot-password`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        email: email,
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            )
            setResetPasswordEmail({
                status: resp.status !== 200 ? 'error' : 'success',
                isLoading: false,
            })
        } catch (e) {
            setResetPasswordEmail({
                status: 'error',
                isLoading: false,
            })
            throw new Error('Error while sending reset password email')
        }
    }

    return [
        resetPasswordEmail.status,
        resetPasswordEmail.isLoading,
        sendResetPasswordEmailRequest,
        setResetPasswordEmail,
    ] as const
}

export function SendResetPasswordEmail({ email }: { email: string }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [
        status,
        isLoading,
        sendResetPasswordEmailRequest,
        setResetPasswordEmail,
    ] = useResetPasswordEmail(email)
    const handleTextClick = () => {
        onOpen()
        setResetPasswordEmail({
            status: 'initial',
            isLoading: false,
        })
    }
    return (
        <>
            <Text
                color="#001AFF"
                textDecoration="underline"
                cursor="pointer"
                onClick={handleTextClick}
                role="button"
            >
                Send reset email
            </Text>

            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody
                        display="flex"
                        justifyContent={
                            status === 'initial' ? 'center' : 'flex-start'
                        }
                        pt="10"
                    >
                        {status === 'initial' ? (
                            <Heading>Send password reset</Heading>
                        ) : status === 'success' ? (
                            <Box>
                                <Heading>Success</Heading>
                                <Text>The password email has been sent</Text>
                            </Box>
                        ) : (
                            <Box>
                                <Heading>Error</Heading>
                                <Text>
                                    An error was encountered and the email was
                                    not sent.
                                </Text>
                                <Text>
                                    Please contact the Digital team for
                                    assistance.
                                </Text>
                            </Box>
                        )}
                    </ModalBody>
                    {status !== 'initial' && <Divider />}
                    {!isLoading ? (
                        <ModalFooter
                            display="flex"
                            justifyContent={
                                status === 'initial' ? 'center' : 'flex-end'
                            }
                        >
                            {status === 'initial' && (
                                <Button
                                    mr={3}
                                    colorScheme="gray"
                                    onClick={async () => {
                                        await sendResetPasswordEmailRequest()
                                    }}
                                >
                                    Send it
                                </Button>
                            )}
                            <Button variant="ghost" onClick={onClose}>
                                {status === 'initial' ? (
                                    <span>Cancel</span>
                                ) : (
                                    <span>Close</span>
                                )}
                            </Button>
                        </ModalFooter>
                    ) : (
                        <ModalFooter display="flex" justifyContent="center">
                            <Spinner />
                        </ModalFooter>
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}

export function EditEmail({ email }: { email: string }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [newEmail, setNewEmail] = React.useState('')

    const [
        sendUpdateEmailRequest,
        { data, isLoading, isError, reset },
    ] = useUpdateUserEmailAuth({
        oldEmail: email,
        newEmail: newEmail.toLowerCase(),
    })

    function validateEmailAddress(emailString) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return (
            !!emailString &&
            typeof emailString === 'string' &&
            emailString.match(emailRegex)
        )
    }

    function handleClose() {
        setNewEmail('')
        onClose()
        reset()
    }

    return (
        <>
            <Box display="flex" justifyContent="space-between">
                <h1>Email</h1>
                <Text
                    color="#001AFF"
                    textDecoration="underline"
                    cursor="pointer"
                    onClick={() => onOpen()}
                    role="button"
                >
                    Edit email {Pen()}
                </Text>
            </Box>

            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton onClick={() => handleClose()} />
                    <ModalBody pt="10">
                        {!isError && !data && (
                            <Box>
                                <ModalHeader padding="0">
                                    <Box>
                                        <Heading>Edit email</Heading>
                                        <Text
                                            fontWeight="normal"
                                            fontSize="medium"
                                        >
                                            This tool will change a user's
                                            email. Be sure the email is entered
                                            correctly.
                                        </Text>
                                    </Box>
                                </ModalHeader>
                                <Divider margin="16px 0px" />
                            </Box>
                        )}
                        {!isError && !data && (
                            <Box marginTop={6}>
                                <Text>Current email: {email}</Text>
                            </Box>
                        )}
                        {isError ? (
                            <Box>
                                <Heading>Error</Heading>
                                <Text>
                                    An error was encountered and the email
                                    address was not changed.
                                </Text>
                                <Text>
                                    Please contact the Digital team for
                                    assistance.
                                </Text>
                            </Box>
                        ) : (
                            !isLoading &&
                            !isError &&
                            data && (
                                <Box>
                                    <Heading>Success</Heading>
                                    <Text>
                                        The email change request is being
                                        processed. You will receive a
                                        confirmation email for each service that
                                        has been updated successfully.
                                    </Text>
                                </Box>
                            )
                        )}
                    </ModalBody>

                    {!isLoading ? (
                        <ModalFooter display="flex" justifyContent="center">
                            {!isError && !data ? (
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    width="100%"
                                >
                                    <Box display="flex">
                                        <Input
                                            name="email address"
                                            type="email"
                                            placeholder="Enter new email"
                                            value={newEmail}
                                            onChange={(event) =>
                                                setNewEmail(event.target.value)
                                            }
                                        />
                                        <Button
                                            ml={2}
                                            colorScheme="gray"
                                            onClick={() =>
                                                sendUpdateEmailRequest()
                                            }
                                            disabled={
                                                !validateEmailAddress(newEmail)
                                            }
                                        >
                                            Update
                                        </Button>
                                    </Box>
                                    <Button
                                        variant="ghost"
                                        onClick={() => handleClose()}
                                    >
                                        <span>Cancel</span>
                                    </Button>
                                </Box>
                            ) : (
                                <Button
                                    variant="ghost"
                                    onClick={() => handleClose()}
                                >
                                    <span>Close</span>
                                </Button>
                            )}
                        </ModalFooter>
                    ) : (
                        <ModalFooter
                            display="flex"
                            justifyContent="center"
                            mb={4}
                        >
                            <Spinner />
                        </ModalFooter>
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}

export function Account({ subject, email }: AccountProps) {
    return (
        <Box>
            <BlockHeader heading="Account" indicator={!!subject} />
            <Text>
                {subject ? `User has an account.` : `Can't find that user.`}
            </Text>
            <Box pt="3">
                <Box>
                    <EditEmail email={email} />
                    <Text>
                        <i>{email}</i>
                    </Text>
                </Box>
                <Box pt="3">
                    <h1>Password</h1>
                    <SendResetPasswordEmail email={email} />
                </Box>
                <Box pt="3">
                    <Box display="flex" justifyContent="space-between">
                        <h1>User Sub</h1>
                        <IconButton
                            aria-label="copy user sub"
                            variant="outline"
                            colorScheme="gray"
                            icon={<CopyIcon />}
                            onClick={() => {
                                navigator.clipboard.writeText(subject)
                            }}
                        >
                            copy
                        </IconButton>
                    </Box>

                    <Text>
                        <i>{subject}</i>
                    </Text>
                </Box>
            </Box>
        </Box>
    )
}
